<template>
  <div class="index" v-html="data.intro">
  </div>
</template>

<script>
  export default{
    data(){
      return{
        data:{},
        uuid:this.$route.query.id
      }
    },
    created() {
      console.log(this.$route)
      this.getDetail()
    },
    methods:{
      async getDetail(){
        let res = await this.$http.get('/official/student/getByUuid',{
          params:{
            uuid: this.uuid
          }
        })
        this.data = res.data.data
        this.data.intro= this.data.intro.replace(/<img/g, '<img style="width:100%" ');
        this.data.intro = this.data.intro.replace(
          /<iframe class="ql-video" frameborder="0" allowfullscreen="true"/g,
          '<video style="width: 100%;" controls><source ');
        this.data.intro = this.data.intro.replace(/<\/iframe>/g, ' type="video/mp4"></video> ');
      }
    }
  }
</script>

<style scoped="" lang="scss">
  .index{
    width: 60%;
    margin: 0 auto;
  }
</style>
